import { Title } from '@solidjs/meta';
import { Heading, Container, Page, BreadcrumbItem, Section, Button, Link } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from '@solidjs/router';

export default function TJCPage() {
	const loc = useLocation();

	return (
		<NoHydration>
			<Title>Troon Junior Club | Play Troon Programs | Troon</Title>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/web/hero/troon-junior-club.jpeg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/play-troon-programs">Play Troon Programs</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Troon Junior Club</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1">Troon Junior Club</Heading>
			</Hero>

			<Container size="small">
				<Page>
					<Section>
						<p>
							Troon is dedicated to growing the game with Junior Golfers, which is why Troon is pleased to offer the
							Troon Junior Club program that builds upon the success of our already popular Troon Family Golf program.
							Benefits include even greater access to Troon facilities for Junior Golfers, fun instructional
							opportunities, access to exclusive events and even a cool hat!
						</p>

						<p>*Valid only for juniors ages 17 and younger.</p>

						<p>
							“We REALLY appreciate this wonderful program with all the benefits you are offering junior golfers and
							their parents. The TJC helped us take our golf budget ($) further.” – Greg O’Sullivan, father of Hannah
							O’Sullivan former TJC member, 2015 US Women’s Amateur Champion and winner of the 2015 Gateway Classic at
							Longbow Golf Club (which made her the youngest winner in the history of the Symetra Tour).
						</p>

						<Heading as="h2" size="h4">
							What You Will Get by Joining
						</Heading>

						<ul class="flex list-disc flex-col gap-2 ps-8">
							<li>
								Troon Junior Club membership allows access to preferred Junior Rates and Programs at participating Troon
								facilities. Click on Golf Benefits tab below for details
							</li>
							<li>Troon Junior Club Nike hat</li>
							<li>TJC bag tag/putting cup</li>
							<li>
								Practice Facility access at select participating facilities at times allowed by each course. Click on
								Golf Benefits tab below for details
							</li>
							<li>Special Junior Clinics</li>
							<li>Up to 20% off discount on summer golf camps at participating facilities</li>
							<li>Exclusive Access to Troon Junior Club Social Media (with parental approval)</li>
							<li>All current Troon Family Golf Benefits</li>
						</ul>

						<Heading as="p" size="h5">
							2025 Troon Junior Club: $35
						</Heading>

						<Button as={Link} href="/play-troon-programs/troon-junior-club/contact" class="size-fit grow-0">
							Join Today
						</Button>
					</Section>
				</Page>
			</Container>
		</NoHydration>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
